import { Box, SvgIcon } from "@mui/material";
import { PlayArrow, ArrowOutward } from "@mui/icons-material";
import github from "#/images/footer/github.svg";
import twitter from "#/images/footer/twitter.svg";
import telegram from "#/images/footer/telegram.svg";
import styles from "./AppFooter.module.css";

const iconSize = "24px";
const anchors = [
  { icon: github, href: "https://github.com/tonkey-app" },
  {
    icon: twitter,
    href: "https://twitter.com/TonkeyApp",
  },
  { icon: telegram, href: "https://t.me/tonkeyapp" },
];
const Anchors = anchors.map(({ icon, href }) => (
  <a href={href} key={href} target="_blank" rel="noreferrer">
    <SvgIcon component={icon} inheritViewBox sx={{ width: iconSize, height: iconSize }} />
  </a>
));

const Footer = () => (
  <footer className={styles.container} style={{ padding: 0 }}>
    <Box
      className="flex justify-between items-center w-full"
      sx={{
        px: { xs: 2, sm: 3 },
      }}
    >
      <div className="flex items-center gap-3">
        <a className={styles.header} href="https://v3.tonkey.app/" target="_blank" rel="noreferrer">
          <div className={`flex items-center ${styles.link}`}>
            <img src="/images/tonkeyv3-logo.svg" alt="Tonkey" className={styles.v3Logo} />
            <span className={styles.v3Text}>V3</span>
            <ArrowOutward className={styles.v3Arrow} />
          </div>
        </a>
        <a className={styles.header} href="https://v1.tonkey.app/" target="_blank" rel="noreferrer">
          <div className={`flex items-center ${styles.link}`}>
            <span className={styles.v1Text}>Original Version</span>
            <ArrowOutward className={styles.v1Arrow} />
          </div>
        </a>
      </div>
      <div className="flex items-center gap-4">{Anchors}</div>
    </Box>
  </footer>
);

export default Footer;
