import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  SvgIcon,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import { useRouter } from "next/router";
import { Pathnames } from "@/config";
import NewSafeIcon from "#/images/welcome/new-safe.svg";
import LoadSafeIcon from "#/images/welcome/load-safe.svg";
import SafeList from "../sidebar/SafeList";
import styles from "./NewSafe.module.css";
import { hasOwnedSafes } from "tonkey-gateway-typescript-sdk";
import { useWallet } from "@/hooks";

const iconSize = "40px";
const NewSafe = () => {
  const [expanded, setExpanded] = useState(false);
  const [isHintsOpen, setIsHintsOpen] = useState(false);
  const theme = useTheme();
  const shouldExpand = useMediaQuery(theme.breakpoints.down("md"));
  const { push } = useRouter();
  const wallet = useWallet();

  const toggleSafeList = () => (shouldExpand ? setExpanded((prev) => !prev) : null);

  const onClickCreate = useCallback(() => {
    push(Pathnames.CreateSafe);
  }, [push]);

  const onClickAdd = useCallback(() => {
    push(Pathnames.LoadSafe);
  }, [push]);

  const closeHints = () => {
    setIsHintsOpen(false);
  };

  useEffect(() => {
    setExpanded(!shouldExpand);
  }, [shouldExpand]);

  useEffect(() => {
    setIsHintsOpen(true);
  }, []);

  return (
    <Grid container spacing={3} className={styles.container}>
      <Grid item className={styles.sidebar}>
        <Accordion className={styles.accordion} onClick={toggleSafeList} expanded={expanded} defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMore />} className="!p-0 !pb-6 !border-[#C3C6CF]">
            <h1 className={styles["sidebar-header"]}>My Tonkey Accounts</h1>
          </AccordionSummary>

          <AccordionDetails sx={{ padding: 0 }} onClick={(event) => event.stopPropagation()}>
            <SafeList />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item flex={1} className="!pt-0 !pl-0">
        <div className={styles.content}>
          <h1 className={styles["content-header"]}>Welcome to Tonkey</h1>
          <p className={styles["content-description"]}>
            Confidently manage your assets with our secure and user-friendly interface.
          </p>

          <Grid container className={styles["content-items-container"]}>
            <Grid item className={styles["content-item-wrapper"]}>
              <Paper sx={{ padding: 4, height: 1 }} className={styles["content-item-container"]}>
                <SvgIcon
                  component={NewSafeIcon}
                  inheritViewBox
                  sx={{ width: iconSize, height: iconSize, color: "#6AAEFF" }}
                />
                <h3 className={styles["content-item-header"]}>Create Tonkey</h3>
                <p className={styles["content-item-description"]}>
                  Tonkey can be managed by single or multiple owners.
                </p>
                <Button variant="contained" onClick={onClickCreate} className={styles["new-safe-button"]}>
                  <Add sx={{ width: "18px", height: "18px", marginRight: "8px" }} />
                  Create new Tonkey
                </Button>
              </Paper>
            </Grid>

            <Grid item className={styles["content-item-wrapper"]}>
              <Paper sx={{ padding: 4, height: 1 }} className={styles["content-item-container"]}>
                <SvgIcon component={LoadSafeIcon} inheritViewBox sx={{ width: iconSize, height: iconSize }} />
                <h3 className={styles["content-item-header"]}>Add existing Tonkey</h3>
                <p className={styles["content-item-description"]}>Own a Tonkey? Add it with its Tonkey address.</p>
                <Button variant="outlined" onClick={onClickAdd} className={styles["load-safe-button"]}>
                  Add Existing Tonkey
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Grid>

      {isHintsOpen && (
        <div className={styles["hints-container"]}>
          <img src="/images/tonkeyv3-logo.svg" alt="Tonkey V3" className={styles["logo"]} />
          <h1 className={styles["hints-title"]}>The latest Tonkey is now live!</h1>
          <p className={styles["hints-description"]}>
            These enhanced features aim to elevate Tonkey&apos;s performance and security, offering users a superior
            experience.
          </p>
          <div>
            <Button className={styles["later-button"]} onClick={closeHints}>
              Later
            </Button>
            <a href="https://v3.tonkey.app/">
              <Button className={styles["visit-button"]}>Experience Now</Button>
            </a>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default NewSafe;
